import './bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { startBrowserApp } from 'cfx/base/createApp';
import { registerActivityService } from 'cfx/common/services/activity/activity.service';
import { IIntlService } from 'cfx/common/services/intl/intl.service';
import { registerLogService } from 'cfx/common/services/log/logService';
import { ConsoleLogProvider } from 'cfx/common/services/log/providers/consoleLogProvider';
import { ServersListType } from 'cfx/common/services/servers/lists/types';
import { IServersService } from 'cfx/common/services/servers/servers.service';
import { IUiService } from 'cfx/common/services/ui/ui.serviceList';

import { ServersListApp } from './components/ServersListApp/ServersListApp';
import { HomePage } from './pages/HomePage/HomePage';
import { ServersListServerDetailsPage } from './pages/ServerDetailsPage/ServerDetailsPage';
import { registerConvarService } from './services/convars/convars.service';
import { registerServersListIntlService } from './services/intl/intl.serversList';
import { registerServersListServersService } from './services/servers/servers.serversList';
import { registerServersListServersConnectService } from './services/servers/serversConnect.serversList';
import { registerServersListServersStorageService } from './services/servers/serversStorage.serversList';
import { registerServersListUiService } from './services/ui/ui.serversList';
import {
  IUiMessageService,
  registerUiMessageService,
} from './services/uiMessage/uiMessage.service';

startBrowserApp({
  defineServices(container) {
    registerLogService(container, [ConsoleLogProvider]);
    registerUiMessageService(container);
    registerServersListIntlService(container);
    registerConvarService(container);
    registerServersListServersConnectService(container);
    registerActivityService(container);
    registerServersListServersStorageService(container);
    registerServersListUiService(container);
    registerServersListServersService(container, {
      listTypes: [ServersListType.All],
    });
  },

  beforeRender(container) {
    // Pre-resolve critical services
    [IUiService, IUiMessageService, IIntlService, IServersService].forEach(
      (serviceId) => container.get(serviceId),
    );
  },

  render: () => (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ServersListApp />}>
          <Route index element={<HomePage listType={ServersListType.All} />} />
          <Route path="detail/*" element={<ServersListServerDetailsPage />} />
        </Route>
        <Route path="/servers" element={<ServersListApp />}>
          <Route index element={<HomePage listType={ServersListType.All} />} />
          <Route path="detail/*" element={<ServersListServerDetailsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ),

  afterRender() {
  },
});
